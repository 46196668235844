// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contentful-recipe-title-jsx": () => import("./../../../src/pages/{ContentfulRecipe.title}.jsx" /* webpackChunkName: "component---src-pages-contentful-recipe-title-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-recipes-jsx": () => import("./../../../src/pages/recipes.jsx" /* webpackChunkName: "component---src-pages-recipes-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-templates-tag-template-jsx": () => import("./../../../src/templates/tag-template.jsx" /* webpackChunkName: "component---src-templates-tag-template-jsx" */)
}

